// komponent preview produktu

import React, { PropsWithChildren, FC, useMemo } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { useSelector } from 'store';
import { useGetProduct } from 'api';
import { IProductMainAttribute } from 'api/types';
import { Label } from 'components/controls';
import Gallery from 'pages/Product/components/Gallery';

import { SeparatorIcon } from 'assets/icons';

import styles from 'theme/components/containers/ProductPreview/ProductPreview.module.scss';

// typ danych wejściowych
interface IProps {
  productId: number;
  setImageHover: (arg: boolean) => void;
}

const ProductPreview: FC<PropsWithChildren<IProps>> = ({ productId, setImageHover }) => {
  const { profile } = useSelector((state) => state.auth);

  // Pobranie produktu
  const { data: product } = useGetProduct(productId);

  // ID aktualnej jednostki
  const unitId = product?.units[0]?.unit_id;
  // aktualna jednostka
  const unit = useMemo(
    () => product?.units.find((unit) => unit.unit_id === unitId),
    [unitId, product]
  );

  const renderMainAttribute = (attribute: IProductMainAttribute, i: number) => {
    return (
      <span key={i}>
        <span>{attribute.name}:&nbsp;</span>
        <span
          className={classNames({
            [styles.special]: attribute.symbol === 'CATEGORY' || attribute.symbol === 'PRODUCER'
          })}>
          {attribute.value}
        </span>
        {product?.main_attributes.length !== i + 1 && (
          <span className={styles.separator}>
            <SeparatorIcon />
          </span>
        )}
      </span>
    );
  };

  if (product) {
    return (
      <>
        <div
          className={classNames(
            'StylePath-Components-Containers-ProductPreview',
            styles.wrapperComponent
          )}
          onMouseLeave={() => setImageHover?.(false)}
          onMouseEnter={() => setImageHover?.(true)}>
          <div className={styles.galleryWrapper}>
            <Gallery images={product.images} />
          </div>
          <div>
            <div className={styles.labels}>
              {product.labels.map((label) => (
                <Label key={label.type} label={label} />
              ))}
            </div>
            <div className={styles.title}>{product.title}</div>
            <div className={styles.details}>
              {product.main_attributes.map((attribute, i) => renderMainAttribute(attribute, i))}
            </div>
            {product.producer_image && (
              <div className={styles.producerLogo}>
                <img src={product.producer_image} />
              </div>
            )}

            {profile?.role !== 'ROLE_OPEN_PROFILE' && (
              <div className={styles.prices}>
                <div>
                  {unit?.old_price_gross && unit.old_price_net && (
                    <>
                      <div className={styles.priceTitle}>
                        <Trans>Cena standardowa</Trans>
                      </div>
                      <div className={styles.price}>
                        <span className={styles.oldPrice}>
                          {unit?.old_price_net_formatted} {product.currency}
                        </span>{' '}
                        <Trans>netto</Trans>
                      </div>
                      <div className={styles.price}>
                        <span className={styles.oldPrice}>
                          {unit?.old_price_gross_formatted} {product.currency}
                        </span>{' '}
                        <Trans>brutto</Trans>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <div className={styles.priceTitle}>
                    <Trans>Twoja cena</Trans>
                  </div>
                  <div className={styles.price}>
                    <span
                      className={classNames(styles.netPrice, {
                        [styles.discountPrice]: unit?.old_price_gross && unit.old_price_net
                      })}>
                      {unit?.price_net_formatted} {product.currency}
                    </span>{' '}
                    <Trans>netto</Trans>
                  </div>
                  <div className={styles.price}>
                    <span>
                      {unit?.price_gross_formatted} {product.currency}
                    </span>{' '}
                    <Trans>brutto</Trans>
                  </div>
                </div>
              </div>
            )}

            <div className={styles.description}>
              <div className={styles.descriptionTitle}>
                <Trans>Opis</Trans>
              </div>
              <div>{product.description}</div>
            </div>
          </div>
        </div>
        <div className={styles.backdrop} />
      </>
    );
  }

  return null;
};

export default ProductPreview;
