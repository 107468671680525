// główny layout - mobilne menu dolne

import React, { useCallback } from 'react';
import { Cart, FileEarmarkText, Grid, HouseFill, List } from 'react-bootstrap-icons';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { reduxActions, useDispatch, useSelector } from 'store';
import { useAppNavigate } from 'hooks';

import { GridIcon, HouseChimneyIcon, MemoIcon, MenuIcon, CartIcon } from 'assets/icons';

import styles from 'theme/components/layouts/MainLayout/components/BottomMenu/BottomMenu.module.scss';

const BottomMenu = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);
  const { isMobileMenu } = useSelector((state) => state.ui);
  const navigate = useAppNavigate();
  const { pathname } = useLocation();

  // TODO wydzielic w inne miejsce i usunąć duplikaty
  const isCurrentPath = useCallback(
    (testedPath?: string) =>
      testedPath ? new RegExp(`^/[^\\/]+${testedPath}$`).test(pathname) : false,
    [pathname]
  );

  const items =
    profile?.role !== 'ROLE_OPEN_PROFILE'
      ? [
          {
            icon: <HouseChimneyIcon />,
            label: <Trans>Start</Trans>,
            onClick: () => {
              navigate('/');
              dispatch(reduxActions.setIsMobileMenu(false));
            },
            active: pathname.split('/').filter((item) => item).length === 1
          },
          {
            icon: <MenuIcon />,
            label: <Trans>Menu</Trans>,
            onClick: () => dispatch(reduxActions.setIsMobileMenu(!isMobileMenu))
          },
          {
            icon: <GridIcon />,
            label: <Trans>Produkty</Trans>,
            onClick: () => {
              navigate('/products');
              dispatch(reduxActions.setIsMobileMenu(false));
            },
            active: isCurrentPath('/products')
          },
          {
            icon: <MemoIcon />,
            label: <Trans>Listy</Trans>,
            onClick: () => null
          },
          {
            icon: <CartIcon />,
            label: <Trans>Koszyk</Trans>,
            onClick: () => null
          }
        ]
      : [
          {
            icon: <HouseChimneyIcon />,
            label: <Trans>Start</Trans>,
            onClick: () => {
              navigate('/');
              dispatch(reduxActions.setIsMobileMenu(false));
            },
            active: pathname.split('/').filter((item) => item).length === 1
          },
          {
            icon: <GridIcon />,
            label: <Trans>Produkty</Trans>,
            onClick: () => {
              navigate('/products');
              dispatch(reduxActions.setIsMobileMenu(false));
            },
            active: isCurrentPath('/products')
          }
        ];

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-BottomMenu'
      )}>
      {items.map((item, index) => (
        <div
          key={index}
          className={classnames(styles.menuItem, { [styles.active]: item.active })}
          onClick={item.onClick}>
          {item.icon}
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default BottomMenu;
