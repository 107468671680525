import React, { useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@mui/material';

import { useRWD } from 'hooks';
import { useGetHomeSubelementsTypes, useGetHomeSubelementItems } from 'api';
import { HidePrices, ProductItem, ProductSlider } from 'components/containers';
import { Container, Loader } from 'components/controls';

import styles from 'theme/pages/Home/components/Tabs/Tabs.module.scss';

const HomeSubelements = ({ isSlider }: { isSlider?: boolean }) => {
  // Aktualnie wybrany typ subelementu
  const [activeSubelementId, setActiveSubelementId] = useState<string | null>(null);

  const { isTablet, isMobile } = useRWD();

  // Pobranie typów subelementów
  const { data: homeSubelementsTypesData } = useGetHomeSubelementsTypes(
    {
      page: 1,
      limit: 24
    },
    {
      onSuccess: (data) => {
        // Ustawienie pierwszego typ subelementu jako wybrany
        setActiveSubelementId(data.items[0]?.id || null);
      }
    }
  );

  // Pobranie listy produktów po zmianie/wybraniu typu subelementu
  const { data: homeSubelementItemsData, isLoading: isHomeSubelementItemsLoading } =
    useGetHomeSubelementItems(
      activeSubelementId || '',
      {
        page: 1,
        limit: 24
      },
      {
        enabled: !!activeSubelementId
      }
    );

  const renderContent = () => {
    if (isSlider) {
      return (
        <ProductSlider
          products={homeSubelementItemsData?.items || []}
          itemsPerSlide={isMobile ? 1 : 6}
        />
      );
    }

    return (
      <Grid
        container
        columnSpacing="16px"
        rowSpacing="20px"
        itemScope
        itemType="http://schema.org/ItemList">
        {homeSubelementItemsData?.items.map((product) => (
          <Grid
            key={product.id}
            item
            md={6}
            lg={4}
            xl={3}
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem">
            <ProductItem product={product} hidePricesEnabled showProductPreview />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home-components-Tabs')}>
      {homeSubelementsTypesData && (
        <div className={styles.productTabsWrapper}>
          <div className={styles.tabsBar}>
            <div className={styles.tabs}>
              {homeSubelementsTypesData.items.map((item) => (
                <div
                  key={item.id}
                  className={classnames(styles.tab, {
                    [styles.active]: item.id === activeSubelementId
                  })}
                  onClick={() => {
                    setActiveSubelementId(item.id);
                  }}>
                  {item.name}
                </div>
              ))}
            </div>

            <HidePrices />
          </div>
          <Container>
            <div className={styles.sliderWrapper}>
              {isHomeSubelementItemsLoading && <Loader />}
              {renderContent()}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default HomeSubelements;
