import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { JournalText, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IProductListItem, IUnit } from 'api/types';
import { useSelector } from 'store';
import { Counter, DropDown, Label, Link, Select } from 'components/controls';
import { AddToFavouritesButton } from 'components/containers';

import styles from 'theme/components/containers/MobileProductItem/MobileProductItem.module.scss';
import {
  usePostProductQuantity,
  usePostProductQuantityDecrement,
  usePostProductQuantityIncrement
} from 'api';
import AddToShoppingListButton from '../AddToShoppingListButton';
import AddToCartButton from '../AddToCartButton';

// typ danych wejściowych
interface Props {
  product: IProductListItem;
  categoryId?: number;
  searchKeywords?: string;
  line?: boolean;
  minimalVariant?: boolean;
  hidePricesEnabled?: boolean;
}

const MobileProductItem: FC<Props> = ({
  product,
  categoryId,
  searchKeywords,
  hidePricesEnabled
}) => {
  const location = useLocation();
  const { profile } = useSelector((state) => state.auth);
  const { hidePrices } = useSelector((state) => state.products);

  // aktualna ilość
  const [quantity, setQuantity] = useState(0);

  // aktualnie zmieniona ilość (sprzed walidacji)
  const [manuallyChangedQuantity, setManuallyChangedQuantity] = useState<number>();

  const [openModal, setOpenModal] = useState(false);

  // ID aktualnego magazynu
  const [warehouseId, setWarehouseId] = useState<number>(product.units[0]?.warehouse_id);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.warehouse_id === warehouseId),
    [warehouseId, product]
  );

  // aktualna jednostka
  const unitId = unit?.unit_id || 0;

  // czy są widoczne ceny
  const disablePrice = hidePricesEnabled && hidePrices;

  // magazyn centralny
  const centralWarehouseStock = product.units.find(
    (unit) => unit.is_central_warehouse
  )?.available_quantity;

  // ustawianie początkowego magazynu
  useEffect(() => {
    if (!centralWarehouseStock && product.is_available) {
      const firstAvailableUnit = product.units.find((unit) => !!unit.available_quantity);

      firstAvailableUnit && setWarehouseId(firstAvailableUnit?.warehouse_id);
    }
  }, []);

  // zmiana ilości po zmianie jednostki
  useEffect(() => {
    unit && setQuantity(unit.minimal_quantity || 1);
  }, [unit]);

  // pobranie informacji o zmniejsonej ilości
  const { mutate: decrementQuantity, isLoading: isDecrementQuantityLoading } =
    usePostProductQuantityDecrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: incrementQuantity, isLoading: isIncrementQuantityLoading } =
    usePostProductQuantityIncrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji ilości
  const {
    mutate: updateQuantity,
    mutateAsync: updateQuantityAsync,
    isLoading: isUpdateQuantityLoading
  } = usePostProductQuantity(product.id, {
    onSuccess: (data) => {
      setQuantity(data.data.value);
    }
  });

  const renderStockSelector = () => {
    return (
      <Select<IUnit>
        onChange={(unit) => unit?.warehouse_id && setWarehouseId(unit.warehouse_id)}
        value={warehouseId}
        {...(unit?.style === 'SPECIAL' && {
          isSpecial: true
        })}
        options={product.units.map((unit) => ({
          value: unit.warehouse_id,
          label: (
            <div className={classnames(styles.unitWrapper)}>
              <div
                className={classnames(styles.warehouseName, {
                  [styles.disabled]: !product.is_available
                })}>
                <img src={unit.warehouse_image_icon} />
                <span>{unit.warehouse_name}</span>
                <span className={classnames(styles.warehouseQty)}>
                  &nbsp;({unit.available_quantity} {unit.shortcut})
                </span>
              </div>
            </div>
          ),
          isDisabled: !unit.is_field_available,
          item: unit
        }))}
      />
    );
  };

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div className={classnames(styles.shoppingListButtonWrapper)}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <div className={classnames(styles.shoppingListWrapper)}>
            <AddToShoppingListButton
              product={product as IProductListItem}
              unit={unit}
              quantity={quantity}
            />
            <AddToFavouritesButton product={product} unitId={unitId} />
          </div>
        )}
      </div>
    ),
    [profile, quantity, unit]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-CartPositions-Components-MobileCart'
      )}
      itemProp="item"
      itemScope
      itemType="http://schema.org/Product">
      <div className={styles.topBar}>
        <div className={styles.labels}>
          {product?.labels.map((label) => (
            <Label key={label.type} label={label} />
          ))}
        </div>
        <DropDown
          label={<ThreeDotsVertical />}
          items={[
            {
              label: (
                <div
                  className={styles.dropdownAction}
                  onClick={() => {
                    setOpenModal(true);
                  }}>
                  <JournalText />
                  <Trans>Dodaj do listy</Trans>
                </div>
              )
            }
          ]}
          withDropdownIcon={false}
        />
      </div>
      <Link to={`/${product.url_link}`} state={{ categoryId, searchKeywords }}>
        <meta itemProp="url" content={window.location.origin + product.url_link} />
        <img itemProp="image" src={product.images[0]?.min} />
      </Link>

      <div className={styles.productInfo}>
        <div itemProp="name" className={styles.title}>
          {product.title}
        </div>
        <div className={styles.producer}>
          <span>INDEX:</span>
          <span>{product.index}</span>
          {product?.producer_name && (
            <>
              <span>|</span>
              <span>
                <Trans>Producent</Trans>:
              </span>
              <span itemScope itemProp="brand" itemType="http://schema.org/Brand">
                <span itemProp="name">{product?.producer_name}</span>
              </span>
            </>
          )}
        </div>
      </div>
      <div className={styles.productContent}>
        {!disablePrice && (
          <div className={styles.priceWrapper}>
            <div>
              <div className={styles.netto}>
                <span>
                  {product?.units[0].old_price_net_formatted} {product.currency}
                </span>
                <span>
                  {product?.units[0].price_net_formatted} {product.currency}
                </span>
                <span>
                  <Trans>netto</Trans>
                </span>
              </div>

              <div className={styles.brutto}>
                <span>
                  {product?.units[0].price_gross_formatted} {product.currency}
                </span>
                <span>
                  <Trans>brutto</Trans>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classnames(styles['product-actions'])}>
        {product.units.length > 1 && profile?.role !== 'ROLE_OPEN_PROFILE' ? (
          renderStockSelector()
        ) : (
          <div>
            <Trans>Jednostka</Trans>:{' '}
            <strong>
              {product?.units?.find((unit) => product.default_unit_id === unit.id)?.name}
            </strong>
          </div>
        )}
      </div>

      <div className={styles.productOrder}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
          <Counter
            onChange={(value) => updateQuantity({ value, unit_id: unitId })}
            onDecrease={() => decrementQuantity({ value: quantity, unit_id: unitId })}
            onIncrease={() => incrementQuantity({ value: quantity, unit_id: unitId })}
            value={quantity}
            onChangeValue={(isChanged, newValue) => setManuallyChangedQuantity(newValue)}
            disabled={
              isDecrementQuantityLoading || isIncrementQuantityLoading || isUpdateQuantityLoading
            }
          />
        ) : (
          <Link
            to={`/login?return_url=${encodeURIComponent(location.pathname + location.search)}`}
            className={styles.loginButton}>
            <Trans>zaloguj</Trans>
          </Link>
        )}

        <AddToCartButton
          isQuantityChanges={!!manuallyChangedQuantity && manuallyChangedQuantity !== quantity}
          updateQuantity={async () => {
            const newQuantity = manuallyChangedQuantity
              ? await updateQuantityAsync({
                  value: manuallyChangedQuantity,
                  unit_id: unitId
                })
              : undefined;

            return newQuantity?.data.value === manuallyChangedQuantity
              ? manuallyChangedQuantity
              : undefined;
          }}
          quantity={quantity}
          unitId={unitId}
          warehouseId={warehouseId}
          productId={product.id}
          large
          className={styles.addToCartButton}
          disabled={
            profile?.role === 'ROLE_OPEN_PROFILE' ||
            isDecrementQuantityLoading ||
            isIncrementQuantityLoading
          }
        />
      </div>
      {renderAddToShoppingListButton()}
    </div>
  );
};

export default MobileProductItem;
